// @flow

import type { File } from '../../src/schema.flow';
import type { Doc } from '../../src/plugins/prismic/types';
import type { ContentType } from './types';

export type Config = {|
  node: { ...$Exact<$Diff<Doc, { type: any }>>, type: ContentType },
  key: string,
  value: File,
|};

const regExpOr = parts => parts.join('|');

const regExpKeyVariations = key => `(^|[^a-z])${key}($|[^a-z])`;

// eslint-disable-next-line no-unused-vars
const IMAGE_FILE_KEY_DEFAULT_REGEXP = new RegExp(
  regExpOr([
    // any field containing `icon`: we assume it will be a small image
    regExpKeyVariations('icon'),
    // any field containing `logo`: we assume it will be a small image
    regExpKeyVariations('logo'),
  ]),
  'igm',
);

module.exports = (unusedConfig: Config): boolean => true