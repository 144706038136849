// @flow
import type { PrismicLinkType, PrismicAppbarSecondary } from '~schema';

import * as contentTypes from '../../config/prismic/contentTypes';

export default function getLinkedAppbarSecondaryType(
  link: ?PrismicLinkType,
): PrismicAppbarSecondary | void {
  const document = link && link.document;
  return document &&
    (document.__typename === 'PrismicAppbarSecondary' ||
      document.type === contentTypes.appbar_secondary)
    ? // $FlowFixMe --> Reason: not infering type from `document.type`
      document
    : undefined;
}
