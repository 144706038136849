// @flow
/* eslint-disable global-require */
import type { Config } from '../../src/plugins/prismic/types';

const getEnvVar = require('../../src/plugins/site/helpers/getEnvVar');

module.exports = ({
  /* GatsbyStarterConfig: prismic credentials */
  repositoryName:
    getEnvVar('PRISMIC_REPOSITORY_NAME') ||
    process.env.PRISMIC_REPOSITORY_NAME ||
    // $FlowFixMe --> Reason: Reading from env var, if missing will throw an error
    process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
  accessToken:
    getEnvVar('PRISMIC_ACCESS_TOKEN') ||
    process.env.PRISMIC_ACCESS_TOKEN ||
    // $FlowFixMe --> Reason: Reading from env var, if missing will throw an error
    process.env.GATSBY_PRISMIC_ACCESS_TOKEN,
  schemas: require('./schemas'),
  // Return true to download the image or false to skip.
  shouldDownloadImage: require('./shouldDownloadImage'),
  linkResolver: require('./linkResolver'),
  htmlSerializer: require('./htmlSerializer'),
  pages: require('./pages'),
  previewPath:
    getEnvVar('PRISMIC_PREVIEW_PATH') ||
    process.env.PRISMIC_PREVIEW_PATH ||
    // $FlowFixMe --> Reason: Reading from env var, if missing will throw an error
    process.env.GATSBY_PRISMIC_PREVIEW_PATH,
  previewPathResolver: require('./previewPathResolver'),
}: Config);
