// @flow
/* eslint-disable no-use-before-define */
import type { Config } from '../../src/plugins/prismic/types';
import type { Page$Context } from '../../src/plugins/page-context/types';
import type {
  PrismicAllStoriesPage,
  PrismicStoriesPage,
  PrismicStory,
  PrismicTravelPage,
  PrismicTravel,
} from '../../src/schema.flow';
import type { ContentType } from './types';

const getLinkedAppbarSecondaryType = require('../../src/helpers/getLinkedAppbarSecondaryType')
  .default;

const pages: $ElementType<Config, 'pages'> = [
  {
    type: ('home_page': ContentType),
    component: 'HomePage.jsx',
  },
  {
    type: ('location_page': ContentType),
    component: 'LocationPage.jsx',
    hasUid: true,
  },
  {
    type: ('all_stories_page': ContentType),
    component: 'AllStoriesPage.jsx',
    hasUid: true,
    getPageContext: (({ data }) => ({
      secondaryAppBar: getLinkedAppbarSecondaryType(data?.appbar_relation),
    }): // $FlowFixMe --> Reason: badly defined types
    (node: PrismicAllStoriesPage) => $Shape<Page$Context>),
    dataQuery: getAppbarDataQuery(),
  },
  {
    type: ('stories_page': ContentType),
    component: 'StoriesPage.jsx',
    hasUid: true,

    getPageContext: (({ data }) => ({
      secondaryAppBar: getLinkedAppbarSecondaryType(data?.appbar_relation),
    }): // $FlowFixMe --> Reason: badly defined types
    (node: PrismicStoriesPage) => $Shape<Page$Context>),
    dataQuery: getAppbarDataQuery(),
  },
  {
    type: ('story': ContentType),
    component: 'StoryPage.jsx',
    hasUid: true,

    getPageContext: (({ data }) => ({
      secondaryAppBar: getLinkedAppbarSecondaryType(data?.appbar_relation),
    }): // $FlowFixMe --> Reason: badly defined types
    (node: PrismicStory) => $Shape<Page$Context>),
    dataQuery: getAppbarDataQuery(),
  },
  {
    type: ('brand_page': ContentType),
    component: 'BrandPage.jsx',
    hasUid: true,

    getPageContext: (({ data }) => ({
      secondaryAppBar: getLinkedAppbarSecondaryType(data?.appbar_relation),
    }): // $FlowFixMe --> Reason: badly defined types
    (node: PrismicBrandPage) => $Shape<Page$Context>),
    dataQuery: getAppbarDataQuery(),
  },
  {
    type: ('travel_page': ContentType),
    component: 'TravelPage.jsx',
    hasUid: true,

    getPageContext: (({ data }) => ({
      secondaryAppBar: getLinkedAppbarSecondaryType(data?.appbar_relation),
    }): // $FlowFixMe --> Reason: badly defined types
    (node: PrismicTravelPage) => $Shape<Page$Context>),
    dataQuery: getAppbarDataQuery(),
  },
  {
    type: ('travel': ContentType),
    component: 'TravelDetailPage.jsx',
    hasUid: true,

    getPageContext: (({ data }) => ({
      secondaryAppBar: getLinkedAppbarSecondaryType(data?.appbar_relation),
    }): // $FlowFixMe --> Reason: badly defined types
    (node: PrismicTravel) => $Shape<Page$Context>),
    dataQuery: getAppbarDataQuery(),
  },
  {
    type: ('redirect': ContentType),
    component: 'redirect/RedirectPage.jsx',
    hasUid: true,
  },
];

module.exports = pages;

function getAppbarDataQuery() {
  return /* graphql */ `
    appbar_relation {
      type
      link_type
      url
      document {
        ... on PrismicAppbarSecondary {
          id
          type
          data {
            appbar_actions {
              action_label {
                text
              }
              action_link {
                type
                link_type
                url
              }
              action_link_section_title {
                text
              }
              action_menu_relation {
                type
                link_type
                url
                document {
                  __typename
                  ... on PrismicMenu {
                    id
                    type
                    data {
                      menu_actions {
                        action_label {
                          text
                          html
                        }
                        action_link {
                          type
                          link_type
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
