// @flow

/* GatsbyStarterConfig: prismic content types */
exports['404_page'] = ('404_page': '404_page');
exports.contact_form = ('contact_form': 'contact_form');
exports.cookie_consent = ('cookie_consent': 'cookie_consent');
exports.home_page = ('home_page': 'home_page');
exports.location_page = ('location_page': 'location_page');
exports.all_stories_page = ('all_stories_page': 'all_stories_page');
exports.stories_page = ('stories_page': 'stories_page');
exports.story = ('story': 'story');
exports.story_tag = ('story_tag': 'story_tag');
exports.store = ('store': 'store');
exports.menu = ('menu': 'menu');
exports.author = ('author': 'author');
exports.organization = ('organization': 'organization');
exports.site_info = ('site_info': 'site_info');
exports.redirect = ('redirect': 'redirect');
exports.appbar_secondary = ('appbar_secondary': 'appbar_secondary');
exports.brand_page = ('brand_page': 'brand_page');
exports.product_list = ('product_list': 'product_list');
exports.travel_page = ('travel_page': 'travel_page');
exports.travel = ('travel': 'travel');
