// @flow
/* eslint-disable global-require */
import type { Schemas } from '../types';

const schemas: Schemas = {
  '404_page': require('./404_page.json'),
  contact_form: require('./contact_form.json'),
  cookie_consent: require('./cookie_consent.json'),
  home_page: require('./home_page.json'),
  location_page: require('./location_page.json'),
  all_stories_page: require('./all_stories_page'),
  stories_page: require('./stories_page.json'),
  story: require('./story'),
  story_tag: require('./story_tag'),
  store: require('./store'),
  menu: require('./menu'),
  author: require('./author'),
  organization: require('./organization'),
  site_info: require('./site_info'),
  redirect: require('./redirect'),
  appbar_secondary: require('./appbar_secondary'),
  brand_page: require('./brand_page'),
  product_list: require('./product_list'),
  travel_page: require('./travel_page'),
  travel: require('./travel'),
};

module.exports = schemas;
