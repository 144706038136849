// @flow
import type { PreviewPathResolver } from '../../src/plugins/prismic/types';
import type { PreviewPathResolvers, PreviewPathResolverByType } from './types';

/* GatsbyStarterConfig: preview paths for Prismic nodes */
const previewPathResolvers: PreviewPathResolvers = {
  '404_page': () => '/404',
  contact_form: undefined,
  cookie_consent: undefined,
  home_page: () => '/',
  location_page: undefined,
  all_stories_page: undefined,
  stories_page: undefined,
  story: undefined,
  story_tag: undefined,
  store: undefined,
  menu: undefined,
  author: undefined,
  organization: undefined,
  site_info: undefined,
  redirect: undefined,
  appbar_secondary: undefined,
  brand_page: undefined,
  product_list: undefined,
  travel_page: undefined,
  travel: undefined,
};

const defaultPreviewPathResolver: PreviewPathResolverByType = ({
  previewPath,
  doc,
}) => `${previewPath}/${doc.type}`;

const previewPathResolver: PreviewPathResolver = ({
  locale,
  previewPath,
}) => doc => {
  const resolvePath: PreviewPathResolverByType =
    previewPathResolvers[doc.type] || defaultPreviewPathResolver;

  return resolvePath({ locale, previewPath, doc });
};

module.exports = previewPathResolver;
